import { inject } from '@angular/core';
import { mgCreateTokenFromFactory } from '@marketguru/cdk';
import { WA_WINDOW } from '@ng-web-apis/common';
const MG_VIEWPORT = mgCreateTokenFromFactory(() => {
  const window = inject(WA_WINDOW);
  return {
    type: 'viewport',
    getClientRect() {
      const rect = {
        top: 0,
        left: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        width: window.innerWidth,
        height: window.innerHeight,
        x: 0,
        y: 0
      };
      return {
        ...rect,
        toJSON: () => JSON.stringify(rect)
      };
    }
  };
});

/**
 * Generated bundle index. Do not edit.
 */

export { MG_VIEWPORT };
