import { inject, ElementRef } from '@angular/core';

/**
 * Функция проверки наличия аттрибута у элемента
 * @param elementRef - elementRef проверяемого элемента
 * @param attribute - название аттрибута
 */
const mgHasAttribute = (elementRef, attribute) => {
  return elementRef.nativeElement.hasAttribute(attribute);
};

/**
 * Функция проверки наличия класса у элемента
 * @param elementRef - elementRef проверяемого элемента
 * @param className - название класса
 */
const mgHasClass = (elementRef, className) => {
  return elementRef.nativeElement.classList.contains(className);
};

/** Функция проверки типа, является ли передаваемый аргумент DOM элементом
 * @param node - проверяемый элемент
 **/
function mgIsElement(node) {
  return !!node && 'nodeType' in node && node.nodeType === Node.ELEMENT_NODE;
}
/** Функция проверки типа, является ли передаваемый аргумент HTML элементом
 * @param node - проверяемый элемент
 **/
function mgIsHTMLElement(node) {
  const defaultView = node?.ownerDocument.defaultView;
  return !!node && !!defaultView && node instanceof defaultView.HTMLElement;
}

/** Функция выполняет инъекцию ElementRef
 * @return nativeElement
 */
function mgInjectElement() {
  return inject(ElementRef).nativeElement;
}
function mgIsInputEvent(event) {
  return 'data' in event && 'inputType' in event;
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgHasAttribute, mgHasClass, mgInjectElement, mgIsElement, mgIsHTMLElement, mgIsInputEvent };
