const rect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0
};
const FALSY_FUNC = () => false;
const EMPTY_ARRAY = [];
const EMPTY_FUNCTION = () => {};
const EMPTY_CLIENT_RECT = {
  ...rect,
  toJSON: () => rect
};
const ERROR_TYPES = {
  required: 'Обязательное поле',
  email: 'Введите корректный email',
  minlength: content => `Минимальная длина ${content.requiredLength} симв.`,
  maxlength: content => `Максимальная длина ${content.requiredLength} симв.`,
  min: content => `Значение должно быть больше ${content.min}`,
  max: content => `Значение не должно быть больше ${content.max}`,
  beforeMoreAfter: content => `Значение должно быть больше ${content.requiredMore}`,
  phone: 'Некорректный номер телефона',
  passwordMismatch: 'Пароли не совпадают',
  pattern: 'Укажите корректное значение',
  customError: content => content
};
function bothEmpty(item1, item2) {
  return Array.isArray(item1) && Array.isArray(item2) && !item1.length && !item2.length;
}
/**
 * Обработчик для сопоставления строковой версии элемента и поискового запроса
 * @param item элемент для сопоставления со строкой
 * @param search поисковой запрос
 * @param stringify обработчик для преобразования элемента в строку
 */
const MG_DEFAULT_MATCHER = (item, search, stringify = String) => stringify(item).toLowerCase().includes(search.toLowerCase());
/**
 * Обработчик для строгого соответствия строковой версии элемента и поискового запроса
 * @param item элемент для сопоставления со строкой
 * @param search поисковой запрос
 * @param stringify обработчик для преобразования элемента в строку
 */
const MG_STRICT_MATCHER = (item, search, stringify = String) => stringify(item).toLowerCase() === search.toLowerCase();
/**
 * Обработчик по умолчанию для проверки равенства двух элементов
 * ВНИМАНИЕ: считает два пустых массива равными
 */
const MG_DEFAULT_IDENTITY_MATCHER = (item1, item2) => item1 === item2 || bothEmpty(item1, item2);

/**
 * Generated bundle index. Do not edit.
 */

export { EMPTY_ARRAY, EMPTY_CLIENT_RECT, EMPTY_FUNCTION, ERROR_TYPES, FALSY_FUNC, MG_DEFAULT_IDENTITY_MATCHER, MG_DEFAULT_MATCHER, MG_STRICT_MATCHER };
