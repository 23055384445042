import { objectValuesToSignals } from '@marketguru/cdk/utils';
import { InjectionToken, inject } from '@angular/core';
function provideExisting(provide, useExisting, multi = false) {
  return {
    provide,
    useExisting,
    multi
  };
}
function mgCreateToken(defaults) {
  return mgCreateTokenFromFactory(() => defaults);
}
function mgCreateTokenFromFactory(factory) {
  return factory ? new InjectionToken('', {
    factory
  }) : new InjectionToken('');
}
function mgProvideOptions(provide, options, fallback) {
  return {
    provide,
    useFactory: () => ({
      ...(inject(provide, {
        optional: true,
        skipSelf: true
      }) || fallback),
      ...options
    })
  };
}
function mgCreateOptions(defaults) {
  const val = objectValuesToSignals(defaults);
  const token = mgCreateToken(val);
  return [token, options => {
    return mgProvideOptions(token, objectValuesToSignals(options), val);
  }];
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgCreateOptions, mgCreateToken, mgCreateTokenFromFactory, mgProvideOptions, provideExisting };
