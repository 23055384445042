import { InjectionToken, inject, DestroyRef, EnvironmentInjector, createComponent, signal } from '@angular/core';
const MAP = new InjectionToken('', {
  factory: () => {
    const map = new Map();
    inject(DestroyRef).onDestroy(() => map.forEach(component => component.destroy()));
    return map;
  }
});
function mgWithStyles(component) {
  const map = inject(MAP);
  const environmentInjector = inject(EnvironmentInjector);
  if (!map.has(component)) {
    map.set(component, createComponent(component, {
      environmentInjector
    }));
  }
  return undefined;
}

/** Функция возвращает строку с px
 * @param value - размер в px
 * @return {String} - строка в виде `${formattedValue}px`*/
const toPx = value => {
  return `${value}px`;
};

/** Функция высчитывает rem из px, hardcode html.font-size как 10px
 * @param value - размер в px
 * @return {String} - строка в виде `${formattedValue}rem`*/
const toRem = value => {
  return `${value / 10}rem`;
};
const objectValuesToSignals = value => {
  const obj = {};
  for (const key in value) {
    obj[key] = signal(value[key]);
  }
  return obj;
};

/**
 * Generated bundle index. Do not edit.
 */

export { mgWithStyles, objectValuesToSignals, toPx, toRem };
