import { trigger, transition, style, animate } from '@angular/animations';
const dropdownAnimation = trigger('dropdown', [transition(':enter', [style({
  opacity: 0,
  transform: 'translateY(-1rem)'
}), animate('.2s ease-out', style({
  opacity: 1,
  transform: 'translateY(0)'
}))]), transition(':leave', [style({
  opacity: 1,
  transform: 'translateY(0)'
}), animate('.2s ease-in', style({
  opacity: 0,
  transform: 'translateY(-1rem)'
}))])]);
const CONTENT_ANIMATION = trigger('content', [transition('void => right', [style({
  opacity: 0,
  transform: 'translateX(-100%)'
}), animate('0.25s linear', style({
  opacity: '1',
  transform: 'translateX(0)'
}))]), transition('void => left', [style({
  opacity: 0,
  transform: 'translateX(100%)'
}), animate('0.25s linear', style({
  opacity: '1',
  transform: 'translateX(0)'
}))]), transition('right => *, left => *', [style({
  opacity: '1',
  position: 'absolute',
  top: 0
}), animate('0.15s linear', style({
  opacity: '0',
  position: 'absolute'
}))])]);

/**
 * Generated bundle index. Do not edit.
 */

export { CONTENT_ANIMATION, dropdownAnimation };
