import { fromEvent, Observable, pipe, asyncScheduler, debounceTime, filter, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs';
import { inject, NgZone } from '@angular/core';
function mgFromEventTyped(target, event, options = {}) {
  return fromEvent(target, event, options);
}
function mgZonefull(zone = inject(NgZone)) {
  return source => new Observable(subscriber => source.subscribe({
    next: value => zone.run(() => subscriber.next(value)),
    error: error => zone.run(() => subscriber.error(error)),
    complete: () => zone.run(() => subscriber.complete())
  }));
}
function mgZonefree(zone = inject(NgZone)) {
  return source => new Observable(subscriber => zone.runOutsideAngular(() => source.subscribe(subscriber)));
}
function mgZoneOptimized(zone = inject(NgZone)) {
  return pipe(mgZonefree(zone), mgZonefull(zone));
}
class MgZoneScheduler {
  constructor(zoneConditionFn, scheduler = asyncScheduler) {
    this.zoneConditionFn = zoneConditionFn;
    this.scheduler = scheduler;
  }
  now() {
    return this.scheduler.now();
  }
  schedule(...args) {
    return this.zoneConditionFn(() => this.scheduler.schedule(...args));
  }
}
function mgZonefreeScheduler(zone = inject(NgZone), scheduler = asyncScheduler) {
  return new MgZoneScheduler(zone.runOutsideAngular.bind(zone), scheduler);
}
function mgZonefullScheduler(zone = inject(NgZone), scheduler = asyncScheduler) {
  return new MgZoneScheduler(zone.run.bind(zone), scheduler);
}
function typeahead(
/**
 * Функция, которая будет вызываться для загрузки результатов.
 * @param searchTerm
 */
loadFn,
/**
 * Разрешить ли считать пустую строку допустимым условием поиска.
 * @default true
 */
allowEmptyString = true,
/**
 * Минимальная длина поискового запроса
 * @default 3
 */
minLength = 3,
/**
 * Время между нажатиями клавиш до отправки запроса
 * @default 600
 */
debounce = 600) {
  let shouldAllowSameValue = false;
  return source => {
    return source.pipe(debounceTime(debounce), filter(value => typeof value === 'string'), filter(value => {
      if (value === '') return allowEmptyString;
      return value.length >= minLength;
    }), distinctUntilChanged((prev, current) => {
      if (shouldAllowSameValue) {
        shouldAllowSameValue = false;
        return false;
      }
      return prev === current;
    }), switchMap(searchTerm => loadFn(searchTerm).pipe(takeUntil(source.pipe(tap(() => {
      shouldAllowSameValue = true;
    }))))));
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgFromEventTyped, mgZoneOptimized, mgZonefree, mgZonefreeScheduler, mgZonefull, mgZonefullScheduler, typeahead };
