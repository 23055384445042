/**
 * Ограничивает значение между нижним и верхним лимитом
 *
 * @param value
 * @param min нижний лимит
 * @param max верхний лимит
 */
function mgClamp(value, min, max) {
  return Math.min(max, Math.max(min, value));
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgClamp };
