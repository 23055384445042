import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';
import { mgFromEventTyped } from '@marketguru/cdk/observables';
import { WA_WINDOW } from '@ng-web-apis/common';
import { merge, map, filter, switchMap, of, distinctUntilChanged, share } from 'rxjs';

/**
 * Active element on the document for ActiveZone
 */
const ACTIVE_ELEMENT = new InjectionToken('', {
  factory: () => {
    const win = inject(WA_WINDOW);
    const doc = inject(DOCUMENT);
    const blur$ = mgFromEventTyped(win, 'blur');
    const mousedown$ = mgFromEventTyped(win, 'mousedown');
    return merge(blur$.pipe(map(() => doc.activeElement), filter(element => !!element?.matches('iframe'))), mousedown$.pipe(switchMap(event => {
      return of(event.target);
    }))).pipe(distinctUntilChanged(), share());
  }
});
const MG_VALIDATION_ERRORS = new InjectionToken('');
function provideValidationErrors(value) {
  return {
    provide: MG_VALIDATION_ERRORS,
    useValue: value
  };
}
const CHEVRON_TOKEN = new InjectionToken('CHEVRON_TOKEN');
const MASCOT_TOKEN = new InjectionToken('Path to mascot images');
function provideMascotPath(value) {
  return {
    provide: MASCOT_TOKEN,
    useValue: value
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ACTIVE_ELEMENT, CHEVRON_TOKEN, MASCOT_TOKEN, MG_VALIDATION_ERRORS, provideMascotPath, provideValidationErrors };
